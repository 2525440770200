import request from '@/utils/request'

const contractApi = {
  trade_contract_list: '/trade/contract/',
  trade_contract_create: '/trade/contract/',
  trade_contract_update: '/trade/contract/',
  trade_contract_delete: '/trade/contract/',
  trade_contract_archived: '/trade/contract_archived/'
}

/**
 * 列表
 */
export function contract_list (parameter) {
  return request({
    url: contractApi.trade_contract_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function contract_create (parameter) {
  return request({
    url: contractApi.trade_contract_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function contract_update (parameter, contract_id) {
  return request({
    url: contractApi.trade_contract_update + contract_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function contract_delete (contract_id) {
  return request({
    url: contractApi.trade_contract_delete + contract_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function contract_archived (parameter) {
  return request({
    url: contractApi.trade_contract_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
